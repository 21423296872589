/*
orange #fc3e20
yellow #fef8d7
*/

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: #333333;
  width: 100%;
}

a {
  color: #fc3e20;
}

.loading {
  display: inline-block;
  font-size: 3em;
  position: relative;
  animation: loading 2s infinite;
}

.loading-container h1 {
  color: #333333;
  font-size: 1.5em;
  font-weight: 900;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
  100% {
    transform: rotate(720deg);
    animation-timing-function: ease;
  }
}

/* START FORM ELEMENTS */

form  {
  margin: 0 auto;
}

form input {
  font-family: 'Noto Serif', serif;
  border: 2px solid #333333;
  color: #fc3e20;
  font-size: 1em;
  font-weight: 900;
  margin: 1em;
  padding: .5em;
  box-shadow: 2px 3px 1px #333333;
}

form button {
  font-family: 'Noto Serif', serif;
  background-color: #fc3e20;
  border: 2px solid #333333;
  color: #fef8d7;
  font-size: 1em;
  font-weight: 600;
  margin: 0;
  padding: .5em;
  box-shadow: 2px 3px 1px #333333;
}

form button:active {
  box-shadow: 1px 1px 0 #333333;
}

/* END FORM ELEMENTS */

/* START HEADER */

header {
  margin: 0 auto;
  margin-bottom: 2em;
  padding: 2em;
  top: 0;
  text-align: center;
}

header .title {
  border: 2px solid #fc3e20;
  background-color: #fef8d7;
  font-size: 1.5em;
  font-style: italic;
  font-weight: 600;
}

header .link {
  padding-left: 1.5em;
}

header a {
  font-family: 'Noto Serif', serif;
  font-size: 1.2em;
  font-weight: 400;
  padding: .5em;
  text-decoration: none;
}

header a:hover {
  text-decoration: underline;
}

@media all and (max-width: 699px) {
  header .title {
    display: block;
    float: none;
    font-size: 1.8em;
    margin-bottom: .5em;
  }
  
  header nav {
    background-color: #ffffff;
    float: none;
    margin: 0 auto;
    padding-top: 1em;
    text-align: center;
  }

  form button {
    font-size: 1em;
  }
}

/* END HEADER */

/* START HOME */

.home h1 {
  font-size: 1.5em;
}

.home ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.home li {
  font-size: 1em;
  margin: 1em 0;
}

/* END HOME */

/* START COMPONENT WRAP */

.wrap {
  padding: 1em;
  margin: 0 auto;
  overflow: hidden;
  width: 80%;
  text-align: center;
}

@media all and (max-width: 699px) {
  .wrap {
    width: 100%;
  }

  h1 {
    font-size: 1.2em;
  }
}

/* END COMPONENT WRAP */

/* START NEWS */

.stories {
  margin: 0 auto;
  padding: 0;
}

.story {
  background-color: #fef8d7;
  list-style: none;
  margin-bottom: 1em;
  padding: 1em;
  text-align: left;
}

.story-title {
  display: block;
  font-size: 1.5em;
  font-weight: 900;
  margin-bottom: .5em;
}

.story-details {
  margin: 1em 0;
}

.story-author {
  font-size: .9em;
  font-weight: 600;
  margin: 0;
}

.seperator {
  font-size: .9em;
  padding: 0 .5em;
}

.story-date {
  font-weight: 600;
  font-size: .9em;
  margin: 0;
}

/* END NEWS */

/* START PIEDNTITY */

.piedentity-result {
  font-size: 1.5em;
  font-weight: 600;
  margin: 2em auto;
  text-align: left;
  width: 45%;
}

.piedentiy-result-item {
  background-color: #fef8d7;
  margin: 1em 0;
  padding: 1em;
}

.highlight {
  color: #fc3e20;
  font-weight: 900;
}

.disclaimer {
  margin-top: 5em;
  font-size: .5em;
  font-style: italic;
}

@media all and (max-width: 699px) {
  .piedentity-result {
    font-size: 1em;
    width: 80%;
  }
}

/* END PIEDENTITY */

/* START PIEKU */

.haiku-title-container {
  margin: 1em;
}

.haiku-title {
  background-color: #fef8d7;
  color: #fc3e20;
  font-size: 2em;
  font-style: italic;
  font-weight: 900;
  padding: .5em;
  text-decoration: underline;
}

.haiku-lines {
  padding: 2em 0;
}

.haiku-line {
  color: #333333;
  font-style: italic;
  font-size: 2em;
  font-weight: 600;
}

@media all and (max-width: 699px) {
  .haiku-line {
    font-size: 1.2em;
  }

  .haiku-title {
    font-size: 1.2em;
  }
}

/* END PIEKU */